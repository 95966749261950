import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {
  Box,
  Container,
  Flex,
  Heading,
  Section,
  Link,
  Space,
  Text
} from "../components/ui";
import Social from "../components/social";
import MailChimpForm from "../components/mailchimp-form";
import * as styles from "../components/404.css";

export default function Contact(props) {
  const { contactPage } = props.data

  return (
    <Layout {...contactPage}>
      <Box paddingY={5}>
        <Container width="narrow">

          <Section>
            <Flex gap={4} alignItems={"baseline"} variant="responsive">
              <Box width="half">
                <Heading as="h2">
                  Subscribe
                </Heading>
                <Text as="p">Join our mailing list for news and updates</Text>

                <MailChimpForm/>

              </Box>
              <Box width="half">
                <Heading as="h2">
                  Follow
                </Heading>
                <Text as="p">Follow our social channels</Text>

                <Social mail={true}/>
                <Space size={4}/>

                <Heading as="h2">
                  FAQ
                </Heading>
                <Text as="p">Please review our <Link to="/faq">frequently asked questions</Link></Text>
                <Text as="p">If you cant find the answer you are looking for <Link target={"_blank"} to={"mailto:"+contactPage.siteEmail}>contact us</Link></Text>

              </Box>
            </Flex>
          </Section>
        </Container>
      </Box>
    </Layout>


  )
}

export const query = graphql`
  {
    contactPage {
      id
      title
      description
      siteEmail
      image {
        id
        url
      }
    }
  }
`

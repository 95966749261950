import * as React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import * as styles from "./mailchimp-form.css"
import * as uiStyles from "./ui.css"
//import TextField from "@material-ui/core/TextField"
//import Button from "@material-ui/core/Button"
//import { Typography } from "@material-ui/core"

const classes = `required email ${styles.inputField}`

export default class MailChimpForm extends React.Component {
	state = {
		name: null,
		email: null,
	}

	_handleChange = e => {
		console.log({
			[`${e.target.name}`]: e.target.value,
		})
		this.setState({
			[`${e.target.name}`]: e.target.value,
		})
	}

	_handleSubmit = e => {
		e.preventDefault()

		console.log('submit', this.state)

		addToMailchimp(this.state.email, this.state)
			.then(({ msg, result }) => {
				console.log('msg', `${result}: ${msg}`)

				if (result !== 'success') {
					throw msg
				}
				alert(msg)
			})
			.catch(err => {
				console.log('err', err)
				alert(err)
			})
	}

	render() {
		return (
			<form onSubmit={this._handleSubmit}>

				<div className="mc-field-group">
					<label htmlFor="mce-EMAIL">Email Address</label>
					<input type="email"
								 name="email"
								 className={classes}
								 id="mce-EMAIL"
								 onChange={this._handleChange}
								 required/>
					<span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
				</div>

				<div id="mce-responses" className="clear foot">
					<div className="response" id="mce-error-response" style={{display: "none"}}></div>
					<div className="response" id="mce-success-response" style={{display: "none"}}></div>
				</div>

				<div style={{position: "absolute", left: -5000}} aria-hidden="true">
					<input cx={[uiStyles.buttons["primary"]]} type={"text"} name={"b_e9549305de213bf391e0ad179_154419e4b7"}
								 tabIndex="-1"/>
				</div>

				<div className="optionalParent">
					<div className="clear foot ">
						<input type="submit"
									 value="Subscribe"
									 name="subscribe"
									 id="mc-embedded-subscribe"
									 className={uiStyles.buttons["primary"]}/>
					</div>
				</div>

			</form>
		)
	}
}